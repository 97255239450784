@import '../../scss/variables/colors.module.scss';
@import '../../scss/helpers/modular-typography.module.scss';

.successNotification {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #fff;
  border: 1px solid $primary;
  border-left-width: 4px;
  border-radius: 4px;

  &__successIcon {
    padding-right: 15px;

    svg {
      display: block;
    }
  }

  &__heading {
    @include h5;
  }

  &__closeBtn {
    padding-left: 15px;
  }
}
