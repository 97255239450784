@import '../variables/typography.module.scss';

// @mixin h1() {
//     $h1: 1rem * ($type-scale * $type-scale * $type-scale * $type-scale * $type-scale);
//     font-size: $h1;
//     line-height: ceil($h1 / $blh) * ($blh / $h1);
// }

// @mixin h2() {
//     $h2: 1rem * ($type-scale * $type-scale * $type-scale * $type-scale);
//     font-size: $h2;
//     line-height: ceil($h2 / $blh) * ($blh / $h2);
// }

@mixin h3() {
    $h3: 1rem * ($type-scale * $type-scale * $type-scale);
    font-size: $h3;
    line-height: ceil(calc($h3 / $blh)) * calc($blh / $h3);
}

@mixin h4() {
    $h4: 1rem * ($type-scale * $type-scale);
    font-size: $h4;
    line-height: ceil(calc($h4 / $blh)) * calc($blh / $h4);
}

@mixin h5() {
    $h5: 1rem * $type-scale;
    font-size: $h5;
    line-height: ceil(calc($h5 / $blh)) * calc($blh / $h5);
}

// @mixin h6() {
//     font-size: 1rem;
//     line-height: ceil(1rem / $blh) * ($blh / 1rem);
// }

@mixin s1() {
    $s1: calc(1rem / $type-scale);
    font-size: $s1;
    line-height: ceil(calc($s1 / $blh)) * calc($blh / $s1);
}

// @mixin s2() {
//     $s2: 1rem / $type-scale / $type-scale;
//     font-size: $s2;
//     line-height: ceil($s2 / $blh) * ($blh / $s2);
// }
