@import './../../../scss/helpers/modular-typography.module.scss';
@import './../../../scss/helpers/reposinve-breakpoints.module';

.saveBtnWrapper {
  text-align: right;
  margin-bottom: $blh * 1rem;
}

.errorWrapper {
  margin-bottom: $blh * 1rem;
}

.rolesWrapper {
  max-width: 450px;
}
