@mixin screen-min($min-width) {
    @media screen and (min-width: $min-width) {
        @content;
    }
}

@mixin screen-max($max-width) {
    @media screen and (max-width: ($max-width - 1)) {
        @content;
    }
}
