@import '../../../scss/helpers/reposinve-breakpoints.module.scss';
@import '../../../scss/variables/magic-numbers.module.scss';
@import '../../../scss/variables/typography.module.scss';

.page {
  min-height: calc(100vh - #{$mui-navbar-max-height});
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2em 2em 4em;

  &__content {
    flex: 1 1 auto; // Span the entire remaining height of flex parent
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include screen-min(600px) {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__breadcrumbs {
    margin-bottom: $blh * 0.5rem;
  }

  &__children {
    flex: 1 1 auto; // Span the entire remaining height of flex parent
    // This will allow to center children components vertically if necessary
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
