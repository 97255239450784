@import '../../scss/variables/magic-numbers.module.scss';

.loginPage {
    min-height: calc(100vh - #{$mui-navbar-max-height});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
}
